import logo from "./assets/ment.png";
import "./App.css";
import Countdown from "react-countdown";
// create a basic web page which includes a logo and text at center.
// there is a countdown at bottom of the text.

function App() {
  const countdownRenderer = (data) => {
    return (
      <span>
        {data.days} gün {data.hours} saat {data.minutes} dakika {data.seconds}
        saniye
      </span>
    );
  };
  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <h1 className="cs-text">Çok yakında!</h1>
      <Countdown date={new Date("2023-06-01T00:00:00")} className="cd-text" />
    </div>
  );
}

export default App;
